// Init
import axios from "axios";
import { toast } from "react-toastify";
import qs from "qs";

const api = async (method = "get", uri, body) => {
  // API Call
  const url = process.env.REACT_APP_SERVER_URL + uri;
  //   const url = "http://localhost:5000/api" + uri;
  const token = localStorage.getItem("token");
  axios.defaults.headers = {
    Authorization: `Bearer ${token}`,
  };

  return new Promise((resolve, reject) => {
    var config = {
      method,
      url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: qs.stringify(body),
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Methods": "PUT, GET, POST, DELETE, OPTIONS",
    };

    axios(config)
      .then((res) => resolve(res.data))
      .catch((err) => {
        if (err?.response?.status === 403) {
          window.location = "/auth/login";
        } else {
          console.log("API Error --------> ", err);
          toast.error(err?.response?.data?.message || err?.message);
          reject(err);
        }
      });
  });
};

// Export
export default api;
