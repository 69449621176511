// reactstrap components
import { useState, useEffect } from "react";
import api from "api";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

const Carts = () => {
  const [carts, setCarts] = useState([]);
  const [filteredCarts, setFilteredCarts] = useState([]);
  let [filter, setFilter] = useState("");

  useEffect(() => {
    getCarts();
  }, []);

  const getCarts = async () => {
    await api("get", "/admins/carts").then((data) => {
      setCarts(data?.carts);
      setFilteredCarts(data?.carts);
    });
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);

    if (e.target.name) {
      setFilteredCarts(carts.filter((a) => a[5] === e.target.name));
    } else {
      setFilteredCarts(carts);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Carts List</h3>
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="primary"
                        name
                        href="#pablo"
                        role="button"
                        caret
                        size="md"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {filter || "All Carts"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem name="" value="All carts" onClick={handleFilter}>
                          All carts
                        </DropdownItem>
                        <DropdownItem name="abandoned" value="Abandoned carts" onClick={handleFilter}>
                          Abandoned carts
                        </DropdownItem>
                        <DropdownItem name="clicked" value="Clicked carts" onClick={handleFilter}>
                          Clicked carts
                        </DropdownItem>
                        <DropdownItem name="recovered" value="Recovered carts" onClick={handleFilter}>
                          Recovered carts
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Shop</th>
                    <th scope="col">Customer</th>
                    <th scope="col">Items</th>
                    <th scope="col">Abandoned At</th>
                    <th scope="col">Cart Value</th>
                    <th scope="col">Status</th>
                    <th scope="col">Activity</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCarts?.map((item) => {
                    return (
                      <tr>
                        <td>{item[0]}</td>
                        <td>{item[1]}</td>
                        <td>{item[2]}</td>
                        <td>{item[3]}</td>
                        <td>{item[4]}</td>
                        <td>{item[5]}</td>
                        <td>{item[6]}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Carts;
