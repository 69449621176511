// reactstrap components
import { useState, useEffect } from "react";
import api from "api";
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

// core components
import Header from "components/Headers/Header.js";

const Shops = () => {
  const [shops, setShops] = useState([]);
  const [filteredShops, setFilteredShops] = useState([]);
  let [filter, setFilter] = useState("");

  useEffect(() => {
    getShops();
  }, []);

  const getShops = async () => {
    await api("get", "/admins/shops").then((data) => {
      setShops(data?.shops);
      setFilteredShops(data?.shops);
    });
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);

    if (e.target.name) {
      setFilteredShops(shops.filter((a) => a.active?.toString() === e.target.name));
    } else {
      setFilteredShops(shops);
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between ">
                  <h3 className="mb-0">Shops List</h3>
                  <div>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        color="primary"
                        name
                        href="#pablo"
                        role="button"
                        caret
                        size="md"
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {filter || "All Shops"}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem name="" value="All shops" onClick={handleFilter}>
                          All Shops
                        </DropdownItem>
                        <DropdownItem name="true" value="Active shops" onClick={handleFilter}>
                          Active shops
                        </DropdownItem>
                        <DropdownItem name="false" value="In-Active shops" onClick={handleFilter}>
                          In-Active shops
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">Shop</th>
                    <th scope="col">Status</th>
                    <th scope="col">Registered At</th>
                    {/* <th scope="col" /> */}
                  </tr>
                </thead>
                <tbody>
                  {filteredShops?.map((item) => {
                    return (
                      <tr>
                        <td>{item?.id}</td>
                        <td>{item?.shop}</td>
                        <td>{item?.active ? "Active" : "In-Active"}</td>
                        <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Shops;
